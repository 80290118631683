import {createRouter, createWebHistory} from 'vue-router';
import {defineAsyncComponent} from "vue";
import request from "@/assets/js/request/request.js";

function setTitle(title) {
    document.title = typeof title === "string" ? title : "广西大学程序设计竞赛服务平台";
}

const router = createRouter({
    history: createWebHistory(),  // history 模式
    routes: [
        {
            path: "/mobile/signup/:itemID(\\d+)/1",
            name: 'MobileSignUp',
            meta: {
                title: '东信杯报名',
            },
            component: defineAsyncComponent(() => import('@/mobileViews/public/signUp/SignUp.vue'))
        },
        {
            path: "/mobile/signup/:itemID(\\d+)/2",
            name: 'MobileSignUpLQ',
            meta: {
                title: '蓝桥杯转账记录',
            },
            component: defineAsyncComponent(() => import('@/mobileViews/public/signUp/SignUpLQ.vue'))
        },
        {
            path: "/mobile/signup/:itemID(\\d+)/3",
            name: 'MobileSignUpNanNing',
            meta: {
                title: '南宁市赛报名',
            },
            component: defineAsyncComponent(() => import('@/mobileViews/public/signUp/SignUpNanNing.vue'))
        },
        {
            path: '/',
            name: 'Public',
            component: defineAsyncComponent(() => import('@/views/Public.vue')),
            children: [
                {
                    path: '',
                    name: 'Home',
                    meta: {
                        title: '首页',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/Home.vue'))
                },
                {
                    path: 'download',
                    name: 'Download',
                    meta: {
                        title: '证书下载',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/Download.vue')),
                },
                {
                    // 东信杯-报名表单
                    path: 'signup/:itemID(\\d+)/1',
                    name: 'SignUp',
                    meta: {
                        title: '东信杯报名',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/signUp/SignUp.vue'))
                },
                {
                    // 蓝桥杯-转账记录
                    path: 'signup/:itemID(\\d+)/2',
                    name: 'SignUpLQ',
                    meta: {
                        title: '蓝桥杯转账记录',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/signUp/SignUpLQ.vue'))
                },
                {
                    // 南宁市赛-报名表单
                    path: 'signup/:itemID(\\d+)/3',
                    name: 'SignUpNanNing',
                    meta: {
                        title: '南宁市赛报名',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/signUp/SignUpNanNing.vue'))
                },
                {
                    path: 'login',
                    name: 'Login',
                    meta: {
                        title: '登录',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/Login.vue')),
                },
                {
                    path: 'board/:itemID(\\d+)',
                    name: 'Board',
                    meta: {
                        title: '公告',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/Board.vue')),
                },
                {
                    path: 'pages/:itemID(\\d+)',
                    name: 'Page',
                    meta: {
                        title: '文章',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/page/Page.vue'))
                },
                {
                    path: 'list/:listType(notice|news|board|winners|prize|signup)',
                    name: 'PageList',
                    component: defineAsyncComponent(() => import('@/views/public/page/PageList.vue'))
                },
                {
                    path: 'reply',
                    name: 'reply',
                    meta: {
                        title: '评论',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/Reply.vue'))
                },
                {
                    path: 'query',
                    name: 'AccountQuery',
                    meta: {
                        title: '比赛账号查询',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/signUp/AccountQuery.vue'))
                },
                {
                    path: 'form',
                    name: 'Form',
                    meta: {
                        title: '表单',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/Form.vue'))
                },
                {
                    path: 'contest',
                    name: 'Contest',
                    meta: {
                        title: '比赛',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/Contest.vue'))
                },
                {
                    path: 'signup/query',
                    name: 'SignupQuery',
                    meta: {
                        title: '参赛证查询',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/query/SignUp.vue'))
                },
                {
                    path: 'signup/query/res',
                    name: 'SignUpRes',
                    meta: {
                        title: '参赛证查询结果',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/query/SignUpRes.vue'))
                },
                {
                    path: 'signup/query/res/NnPermission',
                    name: 'NnContestPermission',
                    meta: {
                        title: '南宁市赛参赛证',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/query/NnContestPermission.vue'))
                },
                {
                    path: 'invitation/info',
                    name: 'InvitationInfo',
                    meta: {
                        title: '嘉宾证信息填写',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/query/NnInvitation/Info.vue'))
                },
                {
                    path: 'invitation/NnInvitation',
                    name: 'NnInvitation',
                    meta: {
                        title: '南宁市赛嘉宾证',
                    },
                    component: defineAsyncComponent(() => import('@/views/public/query/NnInvitation/Invitation.vue'))
                }
            ],
            beforeEnter: (to, from, next) => {
                let client = localStorage.getItem('client');
                // 判断userAgent是否为移动端
                let userAgent = navigator.userAgent;
                let mobile =
                    userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                        ? "/m" : "";
                setTitle(to.meta.title);
                if (client === null || client === "null" || client === undefined) {
                    request.get("/public/getClient").then((res) => {
                        if (res.statusCode === 50000) {
                            localStorage.setItem('client', res.data);
                        }
                    });
                }
                if (!to.path.startsWith("/m") && mobile === "/m")
                    next({path: mobile + to.path});
                next();
            }
        },
        {
            path: '/m/',
            name: 'PublicMobile',
            component: defineAsyncComponent(() => import('@/mobileViews/Public.vue')),
            children: [
                {
                    path: '',
                    name: 'HomeMobile',
                    meta: {
                        title: '首页',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Home.vue'))
                },
                {
                    path: 'download',
                    name: 'DownloadMobile',
                    meta: {
                        title: '证书下载',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Download.vue')),
                },
                {
                    // 东信杯-报名表单
                    path: 'signup/:itemID(\\d+)/1',
                    name: 'SignUpMobile',
                    meta: {
                        title: '东信杯报名',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/signUp/SignUp.vue'))
                },
                {
                    // 蓝桥杯-转账记录
                    path: 'signup/:itemID(\\d+)/2',
                    name: 'SignUpLQMobile',
                    meta: {
                        title: '蓝桥杯转账记录',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/signUp/SignUpLQ.vue'))
                },
                {
                    // 南宁市赛-报名表单
                    path: 'signup/:itemID(\\d+)/3',
                    name: 'SignUpNanNingMobile',
                    meta: {
                        title: '南宁市赛报名',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/signUp/SignUpNanNing.vue'))
                },
                {
                    path: 'login',
                    name: 'LoginMobile',
                    meta: {
                        title: '登录',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Login.vue')),
                },
                {
                    path: 'board/:itemID(\\d+)',
                    name: 'BoardMobile',
                    meta: {
                        title: '公告',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Board.vue')),
                },
                {
                    path: 'pages/:itemID(\\d+)',
                    name: 'PageMobile',
                    meta: {
                        title: '文章',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/page/Page.vue'))
                },
                {
                    path: 'list/:listType(notice|news|board|winners|prize|signup)',
                    name: 'PageListMobile',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/page/PageList.vue'))
                },
                {
                    path: 'query',
                    name: 'AccountQueryMobile',
                    meta: {
                        title: '比赛账号查询',
                    },
                    component: defineAsyncComponent(() => import('@/mobileViews/public/signUp/AccountQuery.vue'))
                },
            ],
            beforeEnter: (to, from, next) => {
                let client = localStorage.getItem('client');
                let userAgent = navigator.userAgent;
                let mobile =
                    userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                        ? "/m" : "";
                setTitle(to.meta.title);
                if (client === null || client === "null" || client === undefined) {
                    request.get("/public/getClient").then((res) => {
                        if (res.statusCode === 50000) {
                            localStorage.setItem('client', res.data);
                        }
                    });
                }
                if (to.path.startsWith("/m") && mobile === "")
                    next({path: to.path.replace("/m", "")});
                next();
            }
        },
        {
            path: '/admin',
            name: 'Admin',
            component: defineAsyncComponent(() => import('@/views/Admin.vue')),
            children: [
                {
                    path: '',
                    name: 'Dashboard',
                    meta: {
                        title: '仪表盘',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/dashboard/Dashboard.vue'))
                },
                {
                    path: 'user',
                    name: 'User',
                    meta: {
                        title: '用户管理',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/UserMgr.vue'))
                },
                {
                    path: 'contest/old',
                    name: 'ContestOld',
                    meta: {
                        title: '比赛管理',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/contest/ContestMgr.vue'))
                },
                {
                    path: 'contest/new',
                    name: 'ContestNew',
                    meta: {
                        title: '比赛管理',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/contest/ContestMgrNew.vue'))
                },
                {
                    path: 'contest/editor',
                    name: 'ContestEditor',
                    meta: {
                        title: '比赛编辑器',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/contest/ContestEditor.vue'))
                },
                {
                    path: 'form/old',
                    name: 'FormOld',
                    meta: {
                        title: '表单管理',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/form/FormMgr.vue'))
                },
                {
                    path: 'form/new',
                    name: 'FormNew',
                    meta: {
                        title: '表单管理',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/form/FormMgrNew.vue'))
                },
                {
                    path: 'form/add',
                    name: 'FormEditor',
                    meta: {
                        title: '表单编辑器',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/form/FormEditor.vue'))
                },
                {
                    path: 'reply',
                    name: 'Reply',
                    meta: {
                        title: '评论管理',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/Reply.vue'))
                },

                {
                    path: 'email/old',
                    name: 'EmailOld',
                    meta: {
                        title: '邮件群发',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/email/Email.vue'))
                },
                {
                    path: 'email/new',
                    name: 'EmailNew',
                    meta: {
                        title: '邮件群发',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/email/EmailNew.vue'))
                },
                {
                    path: 'text',
                    name: 'Text',
                    meta: {
                        title: '文字管理',
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/text/TextMgr.vue'))
                },
                {
                    path: "Text/edit/:itemID",
                    name: "EditText",
                    meta: {
                        title: "编辑文字",
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/text/EditText.vue'))
                },
                {
                    path: "Text/add",
                    name: "AddText",
                    meta: {
                        title: "添加文字",
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/text/EditText.vue'))
                },
                {
                    path: "reptile",
                    name: "Reptile",
                    meta: {
                        title: "爬虫管理",
                    },
                    component: defineAsyncComponent(() => import('@/views/admin/Python.vue'))
                }
            ],
            // token验证

            beforeEnter: (to, from, next) => {
                let token = localStorage.getItem('token');
                let logindto = {};
                logindto.token = token;
                logindto.urlpath = to.path;
                setTitle(to.meta.title);
                if (token != null) { //登录过
                    //验证token
                    request.get("/admin/checkToken").then(res => {
                        //成功
                        if (res.statusCode === 50000) {
                            next();
                        } else {
                            next({path: '/login'});
                        }

                    }).catch(() => {
                        next({path: '/login'});
                    });
                } else { //没有登录
                    next({path: '/login'});
                }

            }
        },
        {
            path: "/:catAll(.*)",
            name: "404",
            meta: {
                title: '404',
            },
            component: defineAsyncComponent(() => import('@/views/404.vue'))
        }
    ]
});

router.beforeEach((to, from, next) => {
    setTitle(to.meta.title);
    next();
});

export default router;
